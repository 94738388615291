function requestCameraAccess() {
    const camera_feed = document.getElementById('video');
    if (camera_feed) {
        window.navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } }).then(stream => {
            camera_feed.srcObject = stream;
            camera_feed.onloadeddata = async (e) => {
                camera_feed.style.objectFit = 'cover';
                insertAssets();
            }
            camera_feed.load();
        }).catch(err => {
            if (window.confirm('Camera não encontrada. Tentar novamente?')) {
                requestCameraAccess();
            } else {
                window.location.reload();
            }
        });
    } else {
        setTimeout(() => requestCameraAccess(), 500);
    }
}

async function insertAssets() {
    const _loader = document.querySelector('.a-loader-title');
    _loader.style.display = '';
    const _canvas = document.querySelector('.a-canvas');
    _canvas.style.backgroundColor = 'rgba(50, 50, 50, .5)';
    _canvas.style.backdropFilter = 'blur(50px)';
    const _scene = document.querySelector('a-scene');
    const _assets = document.querySelector('a-assets');
    const _data = await Array.from(document.getElementById('assets').children);
    const _sorted = await Math.abs(Math.ceil(Math.random() * _data.length - 1));

    const _asset = document.createElement('a-asset-item');
    _asset.addEventListener('loaded', ev => {
        console.log(ev);
        _canvas.style.backgroundColor = '';
        _loader.style.display = 'none';
        _canvas.style.backdropFilter = '';
    });
    _asset.id = _data[_sorted].dataset.id;
    await _asset.setAttribute('src', _data[_sorted].dataset.src);
    _assets.appendChild(_asset);

    const _ent = document.createElement('a-entity');
    _ent.id = 'mascote_principal';
    if (!_scene.children.namedItem(_ent.id)) {
        _scene.appendChild(_ent);
        _ent.setAttribute('gltf-model', _data[_sorted].dataset.src);
        _ent.setAttribute('position', '0 0 -4');
        _ent.setAttribute('scale', '.5 .5 .5');
        _ent.setAttribute('rotation', '0 ' + (Math.random() * 180 - 90) + ' 0');
        _ent.setAttribute('look-controls', 'enabled: true; magicWindowTrackingEnabled: false; mouseEnabled: false; touchEnabled: true;');
    }
}

function PhotoAR() {
    document.title = 'Foto com o Mascote </br> danonimob';
    // eslint-disable-next-line no-unused-vars
    const AFRAME = require('aframe');
    return (
        <div id='xp_photoAR' className="cropper">
            <div id='assets'>
                <div data-id='model0' data-src='/models/teafant2.glb'></div>
                <div data-id='model1' data-src='/models/relaxaurus.glb'></div>
                <div data-id='model2' data-src='/models/swee.glb'></div>
                <div data-id='model3' data-src='/models/dumud.glb'></div>
                <div data-id='model4' data-src='/models/depresso.glb'></div>
            </div>
            <video autoPlay muted playsInline id="video" ref={window.videoRef}> </video>
            <a-scene id="scene" embedded background="transparent: true;" renderstart={requestCameraAccess()} xr-mode-ui="enabled: false" loading-screen="dotsColor: #1976d2; backgroundColor: lightgrey; enabled: true;">
                <a-assets timeout="15000">
                </a-assets>
                <a-camera position="0 1.85 0" wasd-controls="enabled: false;" look-controls="enabled: true; magicWindowTrackingEnabled: true; mouseEnabled: false; touchEnabled: false;"></a-camera>
            </a-scene>
        </div>
    );
}

export default PhotoAR;