import '@mediapipe/face_detection';
import '@tensorflow/tfjs-core';
import '@tensorflow/tfjs-backend-webgl';
import * as faceDetection from '@tensorflow-models/face-detection';

function isIOS() {
    const regex = /iPhone|iPad|iPod/i;
    return regex.test(navigator.userAgent);
}
function isMacOS() {
    const regex = /macOS|Macintosh|Mac OS X/i;
    return regex.test(navigator.userAgent);
}
let mascaraUUID = '',
    trocando = false,
    inicializado = false,
    deslocX = 0,
    deslocY = 0,
    camera_ini = false,
    oldAZ = 0,
    oldRZ = 0,
    oldRX = 0,
    oldRY = 0,
    margemEscala = 0,
    iOS = isIOS(),
    macOS = isMacOS();

const divEscala = iOS || macOS ? 4.5 : 3.5;

async function initial(camera_feed) {
    if (!inicializado) {
        inicializado = true;
        const model = faceDetection.SupportedModels.MediaPipeFaceDetector;
        const detectorConfig = {
            runtime: 'mediapipe',
            solutionPath: 'https://cdn.jsdelivr.net/npm/@mediapipe/face_detection',
            maxFaces: 3,
        };
        const detector = await faceDetection.createDetector(model, detectorConfig);
        const mascara = await document.getElementById('mascara').getObject3D('mesh');
        await trocar(0);
        runAnimationFrame(camera_feed, detector, mascara);
    }
}
async function runAnimationFrame(camera_feed, detector, mascara) {
    const estimationConfig = { flipHorizontal: true };
    await detector.estimateFaces(camera_feed, estimationConfig).then(async faces => {
        if (faces.length) {
            let escala = ((faces[0].box.width / camera_feed.offsetWidth) / divEscala) * (iOS ? 0.75 : macOS ? 0.85 : 1),
                constX = (((faces[0].keypoints[2].x - faces[0].box.xMin) / (faces[0].box.xMax - faces[0].box.xMin)) - 0.5) / 2,
                constY = (((faces[0].keypoints[2].y - faces[0].box.yMin) / (faces[0].box.yMax - faces[0].box.yMin)) - 0.5) / 2,
                divXY = constY / constX,
                addZ = divXY / 100,
                constZ = (-(((faces[0].keypoints[0].y - faces[0].box.yMax) / (faces[0].keypoints[1].y - faces[0].box.yMax))) + 1) / 2 - ((addZ + oldAZ) / 2),
                novaRotY = ((mascara.children[0].rotation.y + (constX * Math.PI)) / 2 + oldRY) / 2,
                novaRotX = ((mascara.children[0].rotation.x + (constY * Math.PI)) / 2 + oldRX) / 2,
                novaRotZ = ((mascara.children[0].rotation.z + (constZ * Math.PI)) / 2 + oldRZ) / 2,
                novaEscala = (mascara.children[0].scale.x + escala) / 2 + margemEscala,

                novaPosX = (mascara.children[0].position.x + ((faces[0].keypoints[2].x - deslocX) / deslocX) * (novaEscala + 1.5)) / 2,

                novaPosY = ((mascara.children[0].position.y + (-(faces[0].keypoints[2].y - deslocY) / deslocY * (novaEscala + 1.5) - (iOS || macOS ? 0.075 : 0.01 * (1 + margemEscala * 400)))) / 2);
            if (mascara) {
                if (Math.abs(divXY) > 1) {
                    for (Math.abs(divXY); Math.abs(divXY) > 1;) {
                        divXY = divXY / 10;
                        addZ = divXY / 100;
                        constZ = (-(((faces[0].keypoints[0].y - faces[0].box.yMax) / (faces[0].keypoints[1].y - faces[0].box.yMax))) + 1) / 2 - ((addZ + oldAZ) / 2);
                        novaRotZ = ((mascara.children[0].rotation.z + (constZ * Math.PI)) / 2 + oldRZ) / 2;
                        if (Math.abs(divXY) < 1)
                            mascara.children[0].rotation.z = novaRotZ;
                    }
                } else {
                    mascara.children[0].rotation.z = novaRotZ;
                }
                mascara.children[0].rotation.y = novaRotY;
                mascara.children[0].rotation.x = novaRotX;
                mascara.children[0].scale.x = novaEscala;
                mascara.children[0].scale.y = novaEscala;
                mascara.children[0].scale.z = novaEscala;
                mascara.children[0].position.x = novaPosX;
                mascara.children[0].position.y = novaPosY;
                if (mascara.children[0].uuid !== mascaraUUID) {
                    mascaraUUID = mascara.children[0].uuid;
                }
            }
            oldRZ = novaRotZ;
            oldRX = novaRotX;
            oldRY = novaRotY;
            oldAZ = addZ;
        }
        requestAnimationFrame(() => runAnimationFrame(camera_feed, detector, mascara));
    }).catch(() => {
        setTimeout(async () => {
            detector.reset();
            runAnimationFrame(camera_feed, detector, await document.getElementById('mascara').getObject3D('mesh'));
        }, 500);
    });
}

function requestCameraAccess() {
    const camera_feed = document.getElementById('video');
    if (camera_feed && !camera_ini) {
        camera_ini = true;
        window.navigator.mediaDevices.getUserMedia({ video: { facingMode: 'user', frameRate: 30, aspectRatio: 1 } }).then(stream => {
            deslocX = stream.getVideoTracks()[0].getSettings().width / 2;
            deslocY = stream.getVideoTracks()[0].getSettings().height / 2;
            camera_feed.srcObject = stream;
            camera_feed.onloadeddata = async (e) => {
                camera_feed.style.objectFit = 'cover';
                camera_feed.style.transform = 'scaleX(-1)';
                initial(camera_feed);
            }
            camera_feed.load();
        }).catch(err => {
            if (window.confirm('Camera não encontrada. Tentar novamente?')) {
                camera_ini = false;
                requestCameraAccess();
            } else {
                window.location.reload();
            }
            console.log(err);
        });
    } else {
        setTimeout(() => requestCameraAccess(), 500);
    }
}
let apresentados = [];
async function trocar(index) {
    if (!trocando) {
        trocando = true;
        const assets = Array.from(document.querySelectorAll('a-asset-item'));
        const selecao = index !== undefined ? index : Math.floor((Math.random() - 0.01) * assets.length);
        if (apresentados.every(ent => ent !== selecao)) {
            const selecionado = assets[selecao];
            const mascaraEnt = await document.getElementById('mascara');
            const mascara = await mascaraEnt.getObject3D('mesh');
            if (mascaraEnt.hasLoaded) {
                const src = selecionado.getAttribute('src');
                await mascaraEnt.components['gltf-model'].loader.load(src, async modelo => {
                    const scale = mascara.children ? mascara.children[0].scale : mascara.scale;
                    const pos = mascara.children ? mascara.children[0].position : mascara.position;
                    await mascara.children.splice(0, 1, modelo.scene.children[0]);
                    mascara.children[0].position.x = pos.x;
                    mascara.children[0].position.y = pos.y;
                    mascara.children[0].scale.x = scale.x;
                    mascara.children[0].scale.y = scale.y;
                    mascara.children[0].scale.z = scale.z;
                    trocando = false;
                    apresentados.push(selecao);
                });
            } else {
                setTimeout(() => {
                    trocando = false;
                    trocar(index);
                }, 250);
            }
        } else {
            if (apresentados.length !== assets.length) {
                trocando = false;
                trocar(index);
            } else {
                apresentados = [];
                trocando = false;
                trocar(index);
            }
        }
    }
}

function MaskAR() {
    document.title = 'Provador de Acessórios </br> danonimob';
    const AFRAME = require('aframe');
    window.addEventListener('shuffle', () => {
        trocar();
    });
    window.addEventListener('tam+', () => {
        margemEscala += 0.00125;
    });
    window.addEventListener('tam-', () => {
        margemEscala -= 0.00125;
    });
    return (
        <div id='xp_photoAR' className="cropper">
            <video autoPlay muted playsInline id="video" ref={window.videoRef}> </video>
            <a-scene id="scene"
                embedded
                background="transparent: true;"
                renderstart={requestCameraAccess()}
                xr-mode-ui="enabled: false"
                loading-screen="dotsColor: #1976d2; backgroundColor: lightgrey; enabled: true;"
                gltf-model="dracoDecoderPath: https://www.gstatic.com/draco/v1/decoders/;"
                device-orientation-permission-ui='enabled: false;'>
                <a-assets timeout="15000">
                    <a-asset-item id='001' src='/models/oculos_001.glb'></a-asset-item>
                    <a-asset-item id='002' src='/models/oculos_002.glb'></a-asset-item>
                    <a-asset-item id='003' src='/models/oculos_003.glb'></a-asset-item>
                    <a-asset-item id='004' src='/models/oculos_004.glb'></a-asset-item>
                    <a-asset-item id='005' src='/models/oculos_005.glb'></a-asset-item>
                </a-assets>
                <a-entity id='mascara' gltf-model='/models/oculos_001.glb' position='0 10 0'></a-entity>
                <a-camera position='0 0 6'
                    fov='25'
                    near='0.125'
                    look-controls='enabled: false; magicWindowTrackingEnabled: false; mouseEnabled: false; touchEnabled: false;'></a-camera>
            </a-scene>
        </div>
    );
}

export default MaskAR;
