/* eslint-disable no-undef */
/* eslint-disable */
import { isMobile } from '@tensorflow/tfjs-core/dist/device_util';
import React from 'react';
import { KeyboardArrowLeft, KeyboardArrowRight, KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight, KeyboardDoubleArrowDown } from '@mui/icons-material';

const textos = {
    mensagemInicio: {
        pt: 'Carregando...',
        en: 'Loading...'
    },
    cameraNaoEncontrada: {
        pt: 'Camera não encontrada. Tentar novamente?',
        en: 'Camera not found. Try again?'
    },
    fimDeJogo: {
        pt: {
            inicio: 'Você fez ',
            final: ' pontos. Jogar novamente?'
        },
        en: {
            inicio: 'You scored ',
            final: ' points. Play again?'
        }
    },
    titulo: {
        pt: 'Jogo de Basquete [Demo]',
        en: 'Basketball Game Demo'
    }
}
let tempo = 60, tempoObj, finalizado = false, pontoAntigo = 0;
function requestCameraAccess() {
    const camera_feed = document.getElementById('video');
    if (camera_feed) {
        window.navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } }).then(stream => {
            camera_feed.srcObject = stream;
            camera_feed.onloadeddata = async (e) => {
                camera_feed.style.objectFit = 'cover';
                //iniciar();
            }
            camera_feed.load();
        }).catch(err => {
            if (window.confirm((navigator.language !== 'pt-BR' && navigator.language !== 'pt') ? textos.cameraNaoEncontrada.en : textos.cameraNaoEncontrada.pt)) {
                requestCameraAccess();
            } else {
                window.location.reload();
            }
        });
    } else {
        setTimeout(() => requestCameraAccess(), 500);
    }
}
setInterval(() => {
    if (tempo > 0)
        if (tempoObj) {
            tempo--;
            tempoObj.innerHTML = tempo;
        } else {
            tempoObj = document.getElementById('tempo');
        }
    else {
        if (!finalizado) {
            finalizado = true;
            final();
        }
    }
}, 1000);
function final() {
    !window.contaPontos && (window.contaPontos = []);
    let jogNov = window.confirm(((navigator.language !== 'pt-BR' && navigator.language !== 'pt') ? textos.fimDeJogo.en.inicio : textos.fimDeJogo.pt.inicio).toString() + window.contaPontos.length.toString() + ((navigator.language !== 'pt-BR' && navigator.language !== 'pt') ? textos.fimDeJogo.en.final : textos.fimDeJogo.pt.final).toString());
    if (jogNov) {
        tempo = 60;
        tempoObj.innerHTML = tempo;
        window.contaPontos = [];
        window.dispatchEvent(new Event('atualizarPontuacao'));
        finalizado = false;
    } else {
        window.location.reload();
    }
}
function BasketAR() {
    isMobile() && requestCameraAccess();
    setTimeout(() => {
        window.esqPerto = document.getElementById('esqPerto');
        window.esqLonge = document.getElementById('esqLonge');
        window.dirPerto = document.getElementById('dirPerto');
        window.dirLonge = document.getElementById('dirLonge');
        window.atras = document.getElementById('atras');
        window.addEventListener('atualizarPontuacao', evento => {
            if (window.navigator.vibrate)
                navigator.vibrate(50);
            document.getElementById('pontuacao').innerHTML = window.contaPontos.length;
            window.parent.medidor = document.getElementById('medidorForca');
            if (contaPontos.length > pontoAntigo) {
                pontoAntigo = contaPontos.length;
                tempo += 5;
            }
        });
        document.title = (navigator.language !== 'pt-BR' && navigator.language !== 'pt') ? textos.titulo.en : textos.titulo.pt;
    }, 500);
    return (
        <div id='xp_basketAR' className="cropper">
            <video autoPlay muted playsInline id="video" ref={window.videoRef}> </video>
            <iframe id='frameJogo' title='Jogo de Basquete' src='/plain/basquete.html' style={{ background: 'transparent' }} />
            <div style={{ position: 'fixed', height: '80vh', top: 0, left: 'calc(50vw - 22.5vh)', zIndex: 800, display: 'flex' }}>
                <KeyboardArrowLeft id='esqPerto' style={{ display: 'none', zIndex: 800, fill: 'rgba(255, 0, 0, 0.5)', margin: 'auto' }} sx={{ fontSize: 50 * window.devicePixelRatio }} />
                <KeyboardDoubleArrowLeft id='esqLonge' style={{ display: 'none', zIndex: 800, fill: 'rgba(255, 0, 0, 0.5)', margin: 'auto' }} sx={{ fontSize: 50 * window.devicePixelRatio }} />
            </div>
            <div style={{ position: 'fixed', height: '80vh', top: 0, right: 'calc(50vw - 22.5vh)', zIndex: 800, display: 'flex' }}>
                <KeyboardArrowRight id='dirPerto' style={{ display: 'none', zIndex: 800, fill: 'rgba(255, 0, 0, 0.5)', margin: 'auto' }} sx={{ fontSize: 50 * window.devicePixelRatio }} />
                <KeyboardDoubleArrowRight id='dirLonge' style={{ display: 'none', zIndex: 800, fill: 'rgba(255, 0, 0, 0.5)', margin: 'auto' }} sx={{ fontSize: 50 * window.devicePixelRatio }} />
            </div>
            <div style={{ position: 'fixed', left: 0, bottom: '25vh', width: '100vw', zIndex: 800, display: 'flex' }}>
                <KeyboardDoubleArrowDown id='atras' style={{ display: 'none', zIndex: 800, fill: 'rgba(255, 0, 0, 0.5)', margin: 'auto' }} sx={{ fontSize: 50 * window.devicePixelRatio }} />
            </div>
            <p id='pontuacao' className='valJogo jogo esq'>0</p>
            <p id='etiquetaPont' className='info jogo esq'>{(navigator.language !== 'pt-BR' && navigator.language !== 'pt') ? 'Score' : 'Cestas'}</p>
            <p id='tempo' className='valJogo jogo dir'>60</p>
            <p id='etiquetaTempo' className='info jogo dir'>{(navigator.language !== 'pt-BR' && navigator.language !== 'pt') ? 'Time' : 'Tempo'}</p>
            <div className='jogo centro'>
                <p id='medidorForca' style={{ display: '', width: 'max-content', margin: 'auto' }} ref={window.txtRef} className='jogabilidade'>{(navigator.language !== 'pt-BR' && navigator.language !== 'pt') ? textos.mensagemInicio.en : textos.mensagemInicio.pt}</p>
            </div>
        </div>
    );
}

export default BasketAR;
