import * as React from 'react';
import './App.css';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Close, Email, LinkedIn, Share, SmartToy, ViewInAr, Camera, Undo, Adjust, Shuffle, AddCircleOutline, RemoveCircleOutline, DesktopAccessDisabled, DesktopWindowsOutlined, MobileFriendly, HourglassTop, WhatsApp, ScreenRotation, ExploreOutlined, CameraOutlined, Info, OpenInNew, Grading, Summarize, AssignmentInd } from '@mui/icons-material';
import Modal from '@mui/material/Modal';
import PhotoAR from './pages/photo_ar';
import EditAR from './pages/edit_ar';
import MaskAR from 'pages/mask_ar';
import { isMobile } from '@tensorflow/tfjs-core/dist/device_util';
import BasketAR from 'pages/basket_ar';
import * as AFRAME from 'aframe';
import ObjAR from 'pages/obj_ar';
import PosterAR from 'pages/poster_ar';
function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://danonimob.com.br/" translate="no">
        danonimob
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const defaultTheme = createTheme({
  palette: {
    info: { main: '#0288d1' },
    success: { main: '#25d366' },
    warning: { main: '#d2ad19' },
    error: { main: '#c71610' }
  }
});

function App() {
  if (window.location.protocol !== 'https:') {
    window.location.replace('https' + window.location.origin.slice(window.location.origin.indexOf(':')));
  }
  const [open, setOpen] = React.useState(false);
  const [review, setReview] = React.useState(false);
  const [selection, setSelection] = React.useState(0);

  const HandleOpen = (_selection: number) => {
    setSelection(_selection);
    setOpen(true);
  };

  const handleClose = () => {
    window.location.reload();
    setSelection(1500);
    setOpen(false);
  }

  const canvasRef = React.useRef<HTMLCanvasElement | null>(null);
  const videoRef = React.useRef<HTMLVideoElement | null>(null);
  const txtRef = React.useRef<HTMLVideoElement | null>(null);
  (window as any)['videoRef'] = videoRef;
  (window as any)['txtRef'] = txtRef;

  async function capturePhoto() {
    await setReview(true);
    const _canvas = await canvasRef.current;
    const _video = await videoRef.current;
    /* 
    // @ts-ignore */
    const _3dCtx = await document.querySelector('a-scene').components.screenshot.getCanvas('perspective');
    if (_canvas && _video) {
      _canvas.width = _video.offsetWidth;
      _canvas.height = _video.offsetHeight;
      const _ctx = await _canvas.getContext('2d');
      if (_ctx) {
        let _zoomFactor = _video.videoHeight / _video.offsetHeight;
        let _offsetX = (_video.videoWidth - _video.offsetWidth * _zoomFactor);
        _ctx.fillStyle = '#fff';
        await _ctx.fillRect(0, 0, _canvas.width, _canvas.height);
        await _ctx.drawImage(_video, _offsetX / 2, 0, _video.offsetWidth * _zoomFactor, _video.videoHeight, 0, 0, _video.offsetWidth, _video.offsetHeight);
        if (selection !== 2) {
          await _ctx.drawImage(_3dCtx, 0, 0, _video.offsetWidth, _video.offsetHeight);
        } else {
          await _ctx.scale(-1, 1);
          await _ctx.drawImage(_3dCtx, -_video.offsetWidth, 0, _video.offsetWidth, _video.offsetHeight);
        }
      }
    }
    const shareBtn = document.getElementById('shareBtn');
    shareBtn && (shareBtn.style.display = '');
    const resumeBtn = document.getElementById('resumeBtn');
    resumeBtn && (resumeBtn.style.display = '');
    const photoBtn = document.getElementById('photoBtn');
    photoBtn && (photoBtn.style.display = 'none');
    const centralizeBtn = document.getElementById('centralizeBtn');
    centralizeBtn && (centralizeBtn.style.display = 'none');
    const shuffleBtn = document.getElementById('shuffleBtn');
    shuffleBtn && (shuffleBtn.style.display = 'none');
    const tamMa = document.getElementById('tam+Btn');
    tamMa && (tamMa.style.display = 'none');
    const tamMe = document.getElementById('tam-Btn');
    tamMe && (tamMe.style.display = 'none');
  }

  function resumeSimulation() {
    const shareBtn = document.getElementById('shareBtn');
    shareBtn && (shareBtn.style.display = 'none');
    const resumeBtn = document.getElementById('resumeBtn');
    resumeBtn && (resumeBtn.style.display = 'none');
    const photoBtn = document.getElementById('photoBtn');
    photoBtn && (photoBtn.style.display = '');
    const centralizeBtn = document.getElementById('centralizeBtn');
    centralizeBtn && (centralizeBtn.style.display = '');
    const shuffleBtn = document.getElementById('shuffleBtn');
    shuffleBtn && (shuffleBtn.style.display = '');
    const tamMa = document.getElementById('tam+Btn');
    tamMa && (tamMa.style.display = '');
    const tamMe = document.getElementById('tam-Btn');
    tamMe && (tamMe.style.display = '');
    emitAnimate();
    setReview(false);
  }

  function centralize() {
    const _sceneEl = document.querySelector('a-scene');
    const _old_camera = document.querySelector('a-camera');
    if (_old_camera) {
      _sceneEl.removeChild(_old_camera);
      var _camera = document.createElement('a-camera');
      _sceneEl.appendChild(_camera);
      _camera.setAttribute('position', '0 1.65 0');
      _camera.setAttribute('rotation', '0 0 0');
      _camera.setAttribute('look-controls', 'enabled', 'true');
      _camera.setAttribute('look-controls', 'touchEnabled', 'false');
      _camera.setAttribute('look-controls', 'mouseEnabled', 'false');
      _camera.setAttribute('look-controls', 'magicWindowTrackingEnabled', 'true');
      _camera.setAttribute('camera', 'active', true);
    }
  }

  function shareImg() {
    const _canvas = canvasRef.current;
    const _snackBar = document.getElementById("snackbar");
    if (_canvas) {
      _canvas.toBlob((__blob) => {
        /* 
        // @ts-ignore */
        var __file = new File([__blob], 'Foto.png', { type: 'image/png' });
        if (navigator.canShare({ files: [__file], title: 'foto_mascote.png' })) {
          navigator.share({ files: [__file] }).then(() => {
            if (_snackBar) {
              _snackBar.innerHTML = 'Compartilhado com sucesso';
              _snackBar.className = 'show success';
              setTimeout(function () { _snackBar.className = _snackBar.className.replace("show", ""); }, 3000);
            }
          }).catch(() => {
            if (_snackBar) {
              _snackBar.innerHTML = 'Compartilhamento Cancelado';
              _snackBar.className = 'show fail';
              setTimeout(function () { _snackBar.className = _snackBar.className.replace("show", ""); }, 3000);
            }
          });
        }
      }, 'image/png', 1);
    }
  }

  function emitShuffle() {
    window.dispatchEvent(new Event("shuffle"));
  }

  function emitTamMa() {
    window.dispatchEvent(new Event("tam+"));
  }

  function emitTamMe() {
    window.dispatchEvent(new Event("tam-"));
  }

  function emitAnimate() {
    window.dispatchEvent(new Event('animate'));
  }

  function mostrarCreditos(index: Number) {
    window.alert('★ AlbertVictory: https://sketchfab.com/albert_victory |'.toString() + ' ★ T-Art: https://sketchfab.com/person-x |'.toString() + ' ★ Peter Tilley: https://sketchfab.com/drydoctoregg |'.toString() + ' ★ ꜰᴇʟɪx ʏᴀᴅᴏᴍɪ: https://sketchfab.com/felixyadomi |'.toString() + ' ★ Marius.Eder: https://sketchfab.com/Marius.Eder');
  }
  async function traduzir(linguagem: String) {
    const _textos = await Array.from(document.querySelectorAll('[data-chave]'));
    const _traducoes = (await fetch('../ling/' + linguagem + '.json'));
    _traducoes.json().then(__tradJSON => {
      _textos.map(__texto => {
        let __dataChave = __texto.attributes.getNamedItem('data-chave');
        if (__dataChave)
          if (__dataChave.value) {
            let __chave: String = __dataChave.value;
            if (__chave) {
              __texto.innerHTML = __tradJSON[__chave.toString()];
            }
          }
      });
    });
  }

  setTimeout(() => {
    if (navigator.language !== 'pt-BR' && navigator.language !== 'pt') {
      traduzir('en-US');
    }
  }, 50);
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar>
          <SmartToy sx={{ mr: 2 }} />
          <Typography variant="h6" color="inherit" noWrap translate="no">
            danonimob - Daniel Rodrigues
          </Typography>
        </Toolbar>
      </AppBar>
      <main>
        {/* Hero unit */}
        <Container sx={{
          px: 0,
          maxWidth: { xs: '95% !important', lg: '85% !important', xl: '75% !important' },
          bgcolor: 'background.paper',
          pt: 4,
          pb: 6,
          justifyContent: 'center',
          display: 'flex'
        }}>
          <Grid container sx={{ py: 0, maxWidth: '90% !important' }}>
            <Grid item
              xs={12} lg={6}>
              <Typography
                variant="h3"
                color="text.primary"
                gutterBottom
                sx={{ textAlign: { xs: 'center', lg: 'left' } }}
                data-chave="tituloCorpo"
              >
                Realidade Aumentada na Web
              </Typography>
            </Grid>
            <Grid item xs={0} lg={6}></Grid>
            <Grid item
              xs={12} lg={8} sx={{ pt: { xs: 3, lg: 2, xl: 0 }, pb: { xs: 0, lg: 2, xl: 3 } }}>
              <Typography
                variant="h6"
                color="text.secondary"
                sx={{ textAlign: { xs: 'center', lg: 'left' } }}
                data-chave="apresentacaoInicial"
              >
                Meu nome é Daniel e eu sou um desenvolvedor webXR de Fortaleza, CE. <br />
              </Typography>
            </Grid>
            <Grid item
              xs={12} lg={4}>
              <Stack
                sx={{ pt: { xs: 3, lg: 2, xl: 0 }, pb: { xs: 3, lg: 2, xl: 0 }, px: { xs: 0, lg: 1, xl: 2 } }}
                direction="row"
                spacing={2}
                justifyContent={{ xs: "center", lg: "end" }}
              >
                <Button sx={{ minWidth: 'max-content', maxHeight: 'fit-content' }} size='medium' variant="contained" color='info' onClick={() => window.open('https://www.linkedin.com/in/dan-rodrigues-dev/')}><LinkedIn /></Button>
                <Button sx={{ minWidth: 'max-content', maxHeight: 'fit-content' }} size='medium' variant="contained" color='warning' onClick={() => window.open('./CV-DanielRodrigues-ReactJSDev.pdf')}><AssignmentInd htmlColor='#fff' /></Button>
                <Button sx={{ minWidth: 'max-content', maxHeight: 'fit-content' }} size='medium' variant="contained" color='success' onClick={() => window.open('https://wa.me/+5585996306354')}><WhatsApp htmlColor='white' /></Button>
                <Button sx={{ minWidth: 'max-content', maxHeight: 'fit-content' }} size='medium' variant="contained" color='error' onClick={() => window.open('mailto:danmartinspr@gmail.com?subject=I Want my WebXR Experience')}><Email htmlColor={'white'} /></Button>
              </Stack>
            </Grid>
            <Grid item
              xs={12} lg={8}>
              <Typography variant="h6" align={"center"} color="text.secondary" sx={{ textAlign: { xs: 'center', lg: 'left' } }}
                data-chave="apresentacaoPortfolio"
              >
                Este portfólio apresenta alguns exemplos de experiências imersivas que rodam 100% no navegador e não exigem nenhum tipo de instalação por parte do usuário final. <br />
                Os exemplos presentes aqui foram desenvolvidos por mim exclusivamente para este fim.
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <Container sx={{ py: 0, maxWidth: '90% !important', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {/* End hero unit */}
          <Typography
            variant="h4"
            align="center"
            color="#1976d2"
            sx={{ pb: 2 }}
            data-chave="chamadaProj"
          >
            Projetos Interessantes
          </Typography>
          <Grid container pb={{ xs: 8, sm: 6 }} spacing={{ xs: 3, sm: 3, md: 3, lg: 4, xl: 5 }} columns={24} sx={{ maxWidth: '95% !important', justifyContent: 'center' }}>
            <Grid item key={0} xs={24} sm={12} md={8} lg={6} xl={5} sx={{ height: 'inherit' }}>
              <Card
                sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
              >
                <CardMedia
                  component="div"
                  sx={{
                    pt: '56.25%',
                    backgroundColor: 'lightgrey'
                  }}
                  image="/images/tonic3-arms_main.webp"
                />
                <CardContent sx={{ flexGrow: 1, paddingBottom: 0 }}>
                  <Typography gutterBottom variant="h5" component="h2" data-chave="proj000" height={"47pt"} alignContent={'center'} textAlign={'center'}>
                    Plataforma ARms
                  </Typography>
                  <Typography data-chave="descProj000" textAlign={'justify'}>
                    Sistema de Gerenciamento de Realidade Aumentada.
                  </Typography>
                  <Typography pt={1} pb={0} sx={{ float: 'right' }}>by Tonic3</Typography>
                </CardContent>
                <CardActions>
                  <Button target={'_blank'} size="small" fullWidth href={'https://tonic3.com/case-studies/tonic3-arms/'} variant="contained" startIcon={<OpenInNew />}><Typography data-chave={"conhecer"}>Saiba mais</Typography></Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item key={1} xs={24} sm={12} md={8} lg={6} xl={5} sx={{ height: 'inherit' }}>
              <Card
                sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
              >
                <CardMedia
                  component="div"
                  sx={{
                    pt: '56.25%',
                    backgroundColor: 'lightgrey'
                  }}
                  image="/images/mcdonalds-ufo_main.webp"
                />
                <CardContent sx={{ flexGrow: 1, paddingBottom: 0 }}>
                  <Typography gutterBottom variant="h5" component="h2" data-chave="proj001" height={"47pt"} alignContent={'center'} textAlign={'center'}>
                    Experiência OVNI McDonald's 2023
                  </Typography>
                  <Typography data-chave="descProj001" textAlign={'justify'}>
                    McDonald's busca enriquecer a experiência do cliente por meio de uma aventura de Realidade Aumentada exclusiva para o Festival de OVNIs.
                  </Typography>
                  <Typography pt={1} pb={0} sx={{ float: 'right' }}>by Tonic3</Typography>
                </CardContent>
                <CardActions>
                  <Button target={'_blank'} size="small" fullWidth href={'https://tonic3.com/case-studies/mcdonalds-ufo/'} variant="contained" startIcon={<OpenInNew />}><Typography data-chave={"conhecer"}>Saiba mais</Typography></Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Container>
        <Container sx={{ py: 0, maxWidth: '90% !important', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {/* End hero unit */}
          <Typography
            variant="h4"
            align="center"
            color="#1976d2"
            sx={{ pb: 2 }}
            data-chave="chamadaExp"
          >
            Experimente alguns exemplos
          </Typography>
          <Grid item container xs={12}>
            <Grid item xs={1} md={2}></Grid>
            <Grid item container pb={2} xs={10} md={8} sx={{ justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
              <Grid item xs={4} sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'center', alignItems: 'center', alignSelf: 'baseline' }}><Info htmlColor='#1976d2' /><Typography color="#1976d2" pl={1} sx={{ textAlign: 'center' }} data-chave="disponivel">Disponível</Typography></Grid>
              <Grid item xs={4} sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'center', alignItems: 'center', alignSelf: 'baseline' }}><Info htmlColor='#8cb6e0ad' /><Typography color="#1976d2" pl={1} sx={{ textAlign: 'center' }} data-chave="indisponivel">Indisponível</Typography></Grid>
              <Grid item xs={4} sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'center', alignItems: 'center', alignSelf: 'baseline' }}><Info htmlColor='#1976d2' sx={{ stroke: '#d2ad19', background: 'radial-gradient(circle closest-corner, white, white, #d2ad19, #d2ad19, white, white)' }} /><Typography color="#1976d2" pl={1} sx={{ textAlign: 'center' }} data-chave="alternativa">Versão Alternativa</Typography></Grid>
            </Grid>
            <Grid item xs={1} md={2}></Grid>
          </Grid>
          <Grid container spacing={{ xs: 3, sm: 3, md: 3, lg: 4, xl: 5 }} columns={24} sx={{ maxWidth: '95% !important', justifyContent: 'center' }}>
            <Grid item key={0} xs={24} sm={12} md={8} lg={6} xl={5} sx={{ height: 'inherit' }}>
              <Card
                sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
              >
                <CardMedia
                  component="div"
                  sx={{
                    // 16:9 
                    pt: '56.25%',
                    backgroundColor: 'lightgrey'
                  }}
                  image="/images/teafant.png"
                />
                <CardContent sx={{ flexGrow: 1, paddingBottom: 0 }}>
                  <Typography gutterBottom variant="h5" component="h2" data-chave="exp000" height={"47pt"} alignContent={'center'} textAlign={'center'}>
                    Foto com o Mascote
                  </Typography>
                  <Typography data-chave="desc000" textAlign={'justify'} >
                    Veja um mascote 3d inserido no mundo real. Tire fotos e compartilhe.
                  </Typography>
                  <Typography color={'rgb(180, 180, 180)'} data-chave="tecn000">
                    React, A-Frame, Three.JS
                  </Typography>
                  <Typography color={'rgb(180, 180, 180)'} data-chave="cred000">
                    Modelo 3d: <a href='https://sketchfab.com/SculptCuteness'>SculptCuteness</a>
                  </Typography>
                </CardContent>
                <CardContent sx={{ flexGrow: 1, alignContent: 'flex-start', paddingTop: 0, paddingBottom: 0 }}>
                  <Typography fontWeight='600' color={'#1976d2'} data-chave="sensores">
                    Sensores:
                  </Typography>
                  <CameraOutlined htmlColor='#1976d2' /> <ExploreOutlined htmlColor='#1976d2' /> <ScreenRotation htmlColor='#1976d2' />
                  <Typography fontWeight='600' color={'#1976d2'} data-chave="plataformas">
                    Plataformas:
                  </Typography>
                  <DesktopAccessDisabled htmlColor='#8cb6e0ad' /><MobileFriendly htmlColor='#1976d2' />
                </CardContent>
                <CardActions>
                  <Button size="small" fullWidth onClick={() => HandleOpen(0)} variant="contained" startIcon={isMobile() ? <ViewInAr /> : <DesktopAccessDisabled />} disabled={!isMobile()}><Typography data-chave={isMobile() ? "botaoExperimentar" : "botaoExpSomenteDispMoveis"}>{isMobile() ? 'Experiment.ar' : 'Apenas Dispositivos Móveis'}</Typography></Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item key={1} xs={24} sm={12} md={8} lg={6} xl={5} sx={{ height: 'inherit' }}>
              <Card
                sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
              >
                <CardMedia
                  component="div"
                  sx={{
                    // 16:9
                    pt: '56.25%',
                    backgroundColor: 'lightgrey'
                  }}
                  image="/images/oculos.png"
                />
                <CardContent sx={{ flexGrow: 1, paddingBottom: 0 }}>
                  <Typography gutterBottom variant="h5" component="h2" data-chave="exp001" height={"47pt"} alignContent={'center'} textAlign={'center'}>
                    Provador de Acessórios
                  </Typography>
                  <Typography data-chave="desc001" textAlign={'justify'}>
                    Veja e alterne entre uma seleção de acessórios. Tire fotos e compartilhe.
                  </Typography>
                  <Typography color={'rgb(180, 180, 180)'} data-chave="tecn001">
                    React, A-Frame, Three.JS, TensorFlow.JS
                  </Typography>
                  <Typography color={'rgb(180, 180, 180)'} data-chave="cred001">
                    Modelos 3d:
                  </Typography>
                  <a id='asd' href='#asd' onClick={() => mostrarCreditos(3)} data-chave="cred001b">Créditos</a>
                </CardContent>
                <CardContent sx={{ flexGrow: 1, alignContent: 'flex-start', paddingTop: 0, paddingBottom: 0 }}>
                  <Typography fontWeight='600' color={'#1976d2'} data-chave="sensores">
                    Sensores:
                  </Typography>
                  <CameraOutlined htmlColor='#1976d2' /> <ExploreOutlined htmlColor='#8cb6e0ad' /> <ScreenRotation htmlColor='#8cb6e0ad' />
                  <Typography fontWeight='600' color={'#1976d2'} data-chave="plataformas">
                    Plataformas:
                  </Typography>
                  <DesktopWindowsOutlined htmlColor='#1976d2' /><MobileFriendly htmlColor='#1976d2' />
                </CardContent>
                <CardActions>
                  <Button size="small" fullWidth onClick={() => HandleOpen(2)} variant="contained" startIcon={<ViewInAr />} ><Typography data-chave="botaoExperimentar">Experiment.AR</Typography></Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item key={2} xs={24} sm={12} md={8} lg={6} xl={5} sx={{ height: 'inherit' }}>
              <Card
                sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
              >
                <CardMedia
                  component="div"
                  sx={{
                    // 16:9
                    pt: '56.25%',
                    backgroundColor: 'lightgrey'
                  }}
                  image="/images/basquete.png"
                />
                <CardContent sx={{ flexGrow: 1, paddingBottom: 0 }}>
                  <Typography gutterBottom variant="h5" component="h2" data-chave="exp002" height={"47pt"} alignContent={'center'} textAlign={'center'}>
                    Demonstração de Jogo de Basquete
                  </Typography>
                  <Typography data-chave="desc002" textAlign={'justify'}>
                    Quantas cestas você consegue acertar antes que o tempo acabe?
                  </Typography>
                  <Typography color={'rgb(180, 180, 180)'} data-chave="tecn002">
                    Ammo Driver Physics System, Three.JS
                  </Typography>
                  <Typography color={'rgb(180, 180, 180)'} data-chave="cred002">
                    Textura da bola: <br /><a href='https://www.robinwood.com/Catalog/FreeStuff/Textures/TexturePages/BallMaps.html'>www.robinwood.com</a>
                  </Typography>
                </CardContent>
                <CardContent sx={{ flexGrow: 1, alignContent: 'flex-start', paddingTop: 0, paddingBottom: 0 }}>
                  <Typography fontWeight='600' color={'#1976d2'} data-chave="sensores">
                    Sensores:
                  </Typography>
                  <CameraOutlined htmlColor='#1976d2' sx={{ background: 'radial-gradient(circle closest-corner, white, white, #d2ad19, #d2ad19, white, white)' }} /> <ExploreOutlined htmlColor='#1976d2' sx={{ background: 'radial-gradient(circle closest-corner, white, white, #d2ad19, #d2ad19, white, white)' }} /> <ScreenRotation htmlColor='#1976d2' sx={{ background: 'radial-gradient(circle closest-corner, white, white, #d2ad19, #d2ad19, white, white)' }} />
                  <Typography fontWeight='600' color={'#1976d2'} data-chave="plataformas">
                    Plataformas:
                  </Typography>
                  <DesktopWindowsOutlined htmlColor='#1976d2' sx={{ stroke: '#d2ad19' }} /><MobileFriendly htmlColor='#1976d2' />
                </CardContent>
                <CardActions>
                  <Button color={isMobile() ? undefined : 'warning'} sx={{ WebkitTextFillColor: isMobile() ? undefined : 'white' }} size="small" fullWidth onClick={() => HandleOpen(1)} variant="contained" startIcon={isMobile() ? <ViewInAr /> : <DesktopWindowsOutlined htmlColor={'white'} />}><Typography data-chave={isMobile() ? "botaoExperimentar" : "botaoVersao"}>{isMobile() ? 'Experiment.AR' : 'Versão Disponível'}</Typography></Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item key={3} xs={24} sm={12} md={8} lg={6} xl={5} sx={{ height: 'inherit' }}>
              <Card
                sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
              >
                <CardMedia
                  component="div"
                  sx={{
                    // 16:9
                    pt: '56.25%',
                    background: 'lightgrey'
                  }}
                  image="/images/breve_Prancheta 1.svg"
                />
                <CardContent sx={{ flexGrow: 1, paddingBottom: 0 }}>
                  <Typography gutterBottom variant="h5" component="h2" data-chave="exp003" height={"47pt"} alignContent={'center'} textAlign={'center'}>
                    Poster Interativo <br /> [Em Breve]
                  </Typography>
                  <Typography data-chave="desc003" textAlign={'justify'}>
                    Experiência totalmente dinâmica, capaz de adicionar interatividade a imagens do mundo real. <br />Experimente em um destes:
                  </Typography>
                  <Typography color={'rgb(180, 180, 180)'}>
                    IMG001<a href=''></a> IMG002<a href=''></a> IMG003<a href=''></a>
                  </Typography>
                  <Typography color={'rgb(180, 180, 180)'} data-chave="tecn003">
                    A-Frame, Three.JS
                  </Typography>
                  <Typography color={'rgb(180, 180, 180)'} data-chave="cred003">
                    Modelos 3d: Link<a href=''></a>
                  </Typography>
                </CardContent>
                <CardContent sx={{ flexGrow: 1, alignContent: 'flex-start', paddingTop: 0, paddingBottom: 0 }}>
                  <Typography fontWeight='600' color={'#1976d2'} data-chave="sensores">
                    Sensores:
                  </Typography>
                  <CameraOutlined htmlColor='#1976d2' /> <ExploreOutlined htmlColor='#8cb6e0ad' /> <ScreenRotation htmlColor='#8cb6e0ad' />
                  <Typography fontWeight='600' color={'#1976d2'} data-chave="plataformas">
                    Plataformas:
                  </Typography>
                  <DesktopWindowsOutlined htmlColor='#1976d2' /><MobileFriendly htmlColor='#1976d2' />
                </CardContent>
                <CardActions>
                  <Button size="small" disabled fullWidth onClick={() => HandleOpen(3)} variant="contained" startIcon={<HourglassTop />}><Typography data-chave="botaoExpEmBreve">{isMobile() ? 'Em Breve' : 'Em Breve'}</Typography></Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item key={4} xs={24} sm={12} md={8} lg={6} xl={5} sx={{ height: 'inherit' }}>
              <Card
                sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
              >
                <CardMedia
                  component="div"
                  sx={{
                    // 16:9
                    pt: '56.25%',
                    background: 'lightgrey'
                  }}
                  image="/images/breve_Prancheta 1.svg"
                />
                <CardContent sx={{ flexGrow: 1, paddingBottom: 0 }}>
                  <Typography gutterBottom variant="h5" component="h2" data-chave="exp004" height={"47pt"} alignContent={'center'} textAlign={'center'}>
                    Reconhecimento de objetos [Em Breve]
                  </Typography>
                  <Typography data-chave="desc004" textAlign={'justify'}>
                    Experiência totalmente dinâmica, capaz de reconhecer objetos do mundo real.
                  </Typography>
                  <Typography color={'rgb(180, 180, 180)'} data-chave="tecn004">
                    TensorFlow.JS, Three.JS
                  </Typography>
                </CardContent>
                <CardContent sx={{ flexGrow: 1, alignContent: 'flex-start', paddingTop: 0, paddingBottom: 0 }}>
                  <Typography fontWeight='600' color={'#1976d2'} data-chave="sensores">
                    Sensores:
                  </Typography>
                  <CameraOutlined htmlColor='#1976d2' /> <ExploreOutlined htmlColor='#8cb6e0ad' /> <ScreenRotation htmlColor='#8cb6e0ad' />
                  <Typography fontWeight='600' color={'#1976d2'} data-chave="plataformas">
                    Plataformas:
                  </Typography>
                  <DesktopWindowsOutlined htmlColor='#1976d2' /><MobileFriendly htmlColor='#1976d2' />
                </CardContent>
                <CardActions>
                  <Button size="small" disabled fullWidth onClick={() => HandleOpen(4)} variant="contained" startIcon={<HourglassTop />}><Typography data-chave="botaoExpEmBreve">{isMobile() ? 'Em Breve' : 'Em Breve'}</Typography></Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item key={5} xs={24} sm={12} md={8} lg={6} xl={5} sx={{ height: 'inherit' }}>
              <Card
                sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
              >
                <CardMedia
                  component="div"
                  sx={{
                    // 16:9
                    pt: '56.25%',
                    background: 'lightgrey'
                  }}
                  image="/images/breve_Prancheta 1.svg"
                />
                <CardContent sx={{ flexGrow: 1, paddingBottom: 0 }}>
                  <Typography gutterBottom variant="h5" component="h2" data-chave="exp005" height={"47pt"} alignContent={'center'} textAlign={'center'}>
                    Personalize o Mascote <br /> [Em Breve]
                  </Typography>
                  <Typography data-chave="desc005" textAlign={'justify'}>
                    Veja um mascote 3d inserido no mundo real. Modifique cores e experimente com os resultados.
                  </Typography>
                  <Typography color={'rgb(180, 180, 180)'} data-chave="tecn005">
                    React, A-Frame, Three.JS
                  </Typography>
                </CardContent>
                <CardContent sx={{ flexGrow: 1, alignContent: 'flex-start', paddingTop: 0, paddingBottom: 0 }}>
                  <Typography fontWeight='600' color={'#1976d2'} data-chave="sensores">
                    Sensores:
                  </Typography>
                  <CameraOutlined htmlColor='#1976d2' /> <ExploreOutlined htmlColor='#1976d2' /> <ScreenRotation htmlColor='#8cb6e0ad' />
                  <Typography fontWeight='600' color={'#1976d2'} data-chave="plataformas">
                    Plataformas:
                  </Typography>
                  <DesktopAccessDisabled htmlColor='#8cb6e0ad' /><MobileFriendly htmlColor='#1976d2' />
                </CardContent>
                <CardActions>
                  <Button size="small" disabled fullWidth onClick={() => HandleOpen(5)} variant="contained" startIcon={<HourglassTop />}><Typography data-chave="botaoExpEmBreve">{isMobile() ? 'Em Breve' : 'Em Breve'}</Typography></Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Container>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className='fullpage app' id='xp-modal-list'>
            {
              selection !== 1500
                ? selection === 0
                  ? <PhotoAR />
                  : selection === 1
                    ? <BasketAR />
                    : selection === 2
                      ? <MaskAR />
                      : selection === 3
                        ? <PosterAR />
                        : selection === 4
                          ? <ObjAR />
                          : <EditAR />
                : <></>
            }
            <div className='interactBar'>
              <div className='interactBtn'>
                <Button size='small' onClick={handleClose} color='secondary'><Close /></Button>
                <Button id='resumeBtn' size='small' onClick={resumeSimulation} style={{ display: 'none' }}><Undo /></Button>
                {
                  selection !== 1 && <Button id='photoBtn' size='small' onClick={capturePhoto}><Camera /></Button>
                }
                {
                  selection === 0
                    ? <Button id='centralizeBtn' size='small' onClick={centralize}><Adjust /></Button>
                    : selection !== 1
                    && <>
                      <Button id='shuffleBtn' size='small' onClick={emitShuffle}><Shuffle /></Button><Button id='tam+Btn' size='small' onClick={emitTamMa}><AddCircleOutline /></Button><Button id='tam-Btn' size='small' onClick={emitTamMe}><RemoveCircleOutline /></Button>
                    </>
                }
                <Button id='shareBtn' size='small' onClick={shareImg} style={{ display: 'none' }}><Share /></Button>
              </div>
              <div id="snackbar"></div>
            </div>
            <Modal
              open={review}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              hideBackdrop={true}
              sx={{ pointerEvents: 'none' }}
            >
              <div className='reviewView'>
                <canvas id='photoView' ref={canvasRef}></canvas>
              </div>
            </Modal>
          </div>
        </Modal>
      </main>
      {/* Footer */}
      <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
          data-chave="rodape"
        >
          Vamos criar e impressionar pessoas juntos!
        </Typography>
        <Copyright />
      </Box>
      {/* End footer */}
    </ThemeProvider>
  );
}

export default App;
